<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useIsFetching } from '@tanstack/vue-query';
import type { RouteLocationRaw } from '#vue-router';

const user = useSupabaseUser();

interface NavigationItem {
	label: string;
	link: RouteLocationRaw;
}

const { t } = useI18n();

const route = useRoute();

const isAdminRoute = computed(() => route.path.split('/').includes('admin'));

const merchantSlug = useMerchantSlug(route);

const merchantId = computed(() => {
	if (!('merchantId' in route.params)) {
		return undefined;
	}

	return String(route.params.merchantId);
});

const navigation = computed<NavigationItem[]>(() => {
	if (isAdminRoute.value && merchantId.value) {
		return [
			{
				label: t('topbar.links.dashboard'),
				link: { name: 'AdminMerchantDashboard', params: { merchantId: merchantId.value } },
			},
			{
				label: t('topbar.links.campaigns'),
				link: { name: 'admin-merchantId-campaigns', params: { merchantId: merchantId.value } },
			},
			{
				label: t('topbar.links.payouts'),
				link: { name: 'admin-merchantId-payouts', params: { merchantId: merchantId.value } },
			},
		];
	}

	if (merchantSlug.value) {
		return [
			{
				label: t('topbar.links.dashboard'),
				link: { name: 'MerchantDashboard', params: { merchantSlug: merchantSlug.value } },
			},
			{
				label: t('topbar.links.campaigns'),
				link: { name: 'MerchantCampaigns', params: { merchantSlug: merchantSlug.value } },
			},
			{
				label: t('topbar.links.submissions'),
				link: { name: 'MerchantSubmissions', params: { merchantSlug: merchantSlug.value } },
			},
			{
				label: t('topbar.links.payouts'),
				link: { name: 'MerchantPayouts', params: { merchantSlug: merchantSlug.value } },
			},
		];
	}

	return [];
});

const hasNavigation = computed(() => navigation.value.length > 0);

const { data: merchantsData } = useMerchantsQuery();
const merchants = useArray(merchantsData);

const hasAdminAccess = computed(() => merchants.value.some((m) => m.isAdmin));

const userNavigation = computed<NavigationItem[]>(() => {
	return [
		...insertIf<NavigationItem>(isAdminRoute.value, {
			label: t('topbar.userMenu.links.merchantDashboard'),
			link: { name: 'index' },
		}),
		...insertIf<NavigationItem>(hasAdminAccess.value && !isAdminRoute.value, {
			label: t('topbar.userMenu.links.adminDashboard'),
			link: { name: 'AdminMerchantSelection' },
		}),
		...insertIf<NavigationItem>(merchantSlug.value, {
			label: t('topbar.userMenu.links.account'),
			link: { name: 'account', params: { merchantSlug: merchantSlug.value! } },
		}),
		{
			label: t('topbar.userMenu.links.logout'),
			link: { name: 'logout' },
		},
	];
});

const isFetching = useIsFetching();

const { data: creatorData } = useCreatorQuery();

const avatarUrlFromUserMetaData = computed(() => user.value?.user_metadata.avatar_url);

const avatarUrl = useCreatorAvatar({ creator: creatorData, fallbackUrl: avatarUrlFromUserMetaData });

const toggleLockScroll = (isNavigationOpen: boolean) => {
	if (isNavigationOpen) {
		document.body.classList.remove('overflow-hidden');
		return;
	}
	document.body.classList.add('overflow-hidden');
};

const merchant = computed(() => merchants.value.find((m) => m.slug === merchantSlug.value));
</script>

<template>
	<Disclosure
		v-slot="{ open }"
		as="nav"
		class="lg:bg-white"
	>
		<UContainer class="bg-primary lg:bg-white relative z-50">
			<div class="flex h-16 justify-between">
				<div class="flex">
					<div class="flex flex-shrink-0 items-center">
						<NuxtLink to="/">
							<img
								class="block h-8 w-auto lg:hidden"
								src="/clickfire-logo-white.png"
								alt="Clickfire"
							>
							<img
								class="h-8 w-auto hidden lg:block"
								src="/clickfire-flame.png"
								alt="Clickfire"
							>
						</NuxtLink>
					</div>
					<div
						v-if="merchant"
						class="flex items-center border-l ml-4 pl-3 self-center py-1 mr-4"
					>
						<img
							v-if="merchant.logoUrl"
							:src="merchant.logoUrl"
							:alt="merchant.name"
							class="h-6"
						>
						<UBadge v-else>
							{{ merchant.name }}
						</UBadge>
					</div>
					<div
						v-if="hasNavigation"
						class="hidden lg:-my-px lg:ml-6 lg:flex lg:space-x-8"
					>
						<NuxtLink
							v-for="item in navigation"
							:key="item.label"
							v-slot="{ isActive }"
							:to="item.link"
							class="inline-flex items-center"
							aria-current="page"
						>
							<span
								:class="[
									'h-full inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
									{
										'border-primary-600 text-gray-900': isActive,
										'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700': !isActive,
									}]"
							>
								{{ item.label }}
							</span>
						</NuxtLink>
					</div>
				</div>
				<div class="flex lg:ml-6 gap-1.5">
					<WidgetLanguageSwitcher class="self-center hidden lg:block relative z-[10000000]" />
					<ClientOnly>
						<div class="hidden lg:flex lg:items-center">
							<Menu
								as="div"
								class="relative ml-3"
							>
								<div>
									<MenuButton class="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
										<span class="absolute -inset-1.5" />
										<span class="sr-only">{{ $t('accessibility.topbar.openUserMenu') }}</span>
										<UAvatar
											:src="avatarUrl"
											:alt="user?.email"
											class="[&>*]:object-cover"
										/>
									</MenuButton>
								</div>
								<Transition
									enter-active-class="transition ease-out duration-200"
									enter-from-class="transform opacity-0 scale-95"
									enter-to-class="transform opacity-100 scale-100"
									leave-active-class="transition ease-in duration-75"
									leave-from-class="transform opacity-100 scale-100"
									leave-to-class="transform opacity-0 scale-95"
								>
									<MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
										<MenuItem
											v-for="item in userNavigation"
											:key="item.label"
											v-slot="{ active, close }"
										>
											<div>
												<NuxtLink
													v-slot="{ href, navigate }"
													custom
													:to="item.link"
												>
													<a
														:href="href"
														:class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
														@click.prevent="() => { close(); navigate() }"
													>
														{{ item.label }}
													</a>
												</NuxtLink>
											</div>
										</MenuItem>
									</MenuItems>
								</Transition>
							</Menu>
						</div>
					</ClientOnly>
					<div class="flex items-center lg:hidden">
						<!-- Mobile menu button -->
						<DisclosureButton
							class="relative inline-flex items-center justify-center rounded-md bg-transparent text-white focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 tranistion-all duration-20"
							@click="toggleLockScroll(open)"
						>
							<span class="sr-only">{{ $t('accessibility.topbar.openMainMenu') }}</span>
							<HamburgerBars
								class="text-white w-12 -m-1"
								:active="open"
							/>
						</DisclosureButton>
					</div>
				</div>
			</div>
		</UContainer>

		<UProgress
			v-show="isFetching"
			size="xs"
			:ui="{ progress: { track: '[&::-webkit-progress-bar]:bg-transparent' } }"
			class="absolute -mt-px"
		/>
		<transition
			enter-active-class="transition duration-300 ease-out"
			enter-from-class="transform -translate-y-[100vh]"
			enter-to-class="transform translate-0"
			leave-active-class="transition duration-300 ease-out"
			leave-from-class="transform translate-0"
			leave-to-class="transform -translate-y-[100vh]"
		>
			<DisclosurePanel
				v-slot="{ close }"
				class="lg:hidden absolute bg-white w-full z-40 top-16 min-h-[calc(100vh-64px)] transition-all duration-300 transform"
			>
				<div
					v-if="hasNavigation"
					class="space-y-1 pb-3 pt-2"
				>
					<NuxtLink
						v-for="item in navigation"
						:key="item.label"
						v-slot="{ isActive }"
						:to="item.link"
						aria-current="page"
						class="w-full block"
						@click="{
							close();
							toggleLockScroll(open);
						}"
					>
						<DisclosureButton
							as="div"
							:class="[
								'block border-l-4 py-2 pl-3 pr-4 text-base font-medium',
								{
									'border-primary-500 bg-primary-50 text-primary-700': isActive,
									'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800': !isActive,
								}]"
						>
							{{ item.label }}
						</DisclosureButton>
					</NuxtLink>
				</div>
				<div
					v-if="user"
					class="border-t border-gray-200 pb-3 pt-4"
				>
					<div class="flex items-center px-4">
						<div class="flex-shrink-0">
							<UAvatar
								:src="avatarUrl"
								:alt="user.email"
								class="[&>*]:object-cover"
							/>
						</div>
						<div class="ml-3">
							<div class="text-base font-medium text-gray-800">
								{{ user.user_metadata.full_name }}
							</div>
							<div class="text-sm font-medium text-gray-500">
								{{ user.email }}
							</div>
						</div>
					</div>
					<div class="mt-3 space-y-1">
						<NuxtLink
							v-for="item in userNavigation"
							:key="item.label"
							:to="item.link"
							class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
							@click="{
								close();
								toggleLockScroll(open);
							}"
						>
							<DisclosureButton as="div">
								{{ item.label }}
							</DisclosureButton>
						</NuxtLink>
					</div>
				</div>
				<div class="flex px-3">
					<WidgetLanguageSwitcher class="w-fit" />
				</div>
			</DisclosurePanel>
		</transition>
	</Disclosure>
</template>
